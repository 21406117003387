import axios from 'axios';
import store from '../store/store';
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

const auth = {
    verify: token => new Promise(async resolve => {
        if (process.env.NODE_ENV === 'development') {
            console.log('AUTHORIZATION BYPASSED WHILE ON DEVELOPMENT MODE\nCurrent token in redux store', store.getState().token)
            return resolve('AUTHORIZED')
        }

        let vResults = 'PENDING';
        try {
            vResults = await axios.get(`${process.env.REACT_APP_FOREST_ANEXSYS_NET}/verify/app-dashboard/${token}`);
        } catch (e) {
            return resolve('REJECTED')
        }
        resolve(vResults.data ? 'AUTHORIZED' : 'REJECTED')
    })
}

const s3proxy = {
    downloadFile: async (app, platform, version) => {
        axios({
            method: 'get',
            url: `${API_ENDPOINT}downloads?app=${app}&platform=${platform}&version=${version}`,
            headers: {
                'X-Amz-Security-Token': store.getState().token,
                'X-Requested-With': 'xmlhttprequest',
            }
        }).then(res => {
            // start download
            window.location = res.data;
        }).catch(console.error);
    },
    generateShareLink: async (app, platform, version, expiry = 60*60*24, params = {}) => {
        let res;
        res = await axios({
            method: 'post',
            url: `${API_ENDPOINT}generate-link`,
            headers: { 'X-Amz-Security-Token': store.getState().token },
            data: { app, platform, version, expiry, params }
        });
        return res.data;
    },
    readIndexJson: async () => {
        let res;
        res = await axios({
            method: 'get',
            url: `${API_ENDPOINT}apps-json`,
            headers: { 'X-Amz-Security-Token': store.getState().token }
        });
        return res.data;
    },
    readJson: async (appName) => {
        let res;
        res = await axios({
            method: 'get',
            url: `${API_ENDPOINT}updates-json?app=${appName}`,
            headers: { 'X-Amz-Security-Token': store.getState().token }
        });
        return res.data;
    },
    // getLatestLinks: appCode => new Promise(async (resolve, reject) => {
    //     const friendlyPlatform = (code) => {
    //         code = code.split('-')[0] || null
    //         switch (code) {
    //             case 'win32': return 'Windows'
    //             case 'darwin': return 'Mac OSX'
    //             case 'linux': return 'Linux'
    //             default: return '*UNKNOWN*';
    //         }
    //     }
    //     const devResponse = {

    //         "papyrus-uploader": {
    //             data: {
    //                 "win32-x64-prod": {
    //                     "update": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/papyrus-uploader/Papyrus Uploader Setup 2.0.3.exe",
    //                     "install": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/papyrus-uploader/Papyrus Uploader Setup 2.0.3.exe",
    //                     "version": "2.0.3"
    //                 },
    //                 "darwin-x64-prod": {
    //                     "update": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/papyrus-uploader/Papyrus Uploader-2.0.3-mac.zip",
    //                     "install": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/papyrus-uploader/Papyrus Uploader-2.0.3-mac.zip",
    //                     "version": "2.0.3"
    //                 },
    //                 "oldVersions": [
    //                     "2.0.0",
    //                     "2.0.1",
    //                     "2.0.2"
    //                 ]
    //             }
    //         },
    //         "anexsys-cli": {
    //             data: {
    //                 "win32-x64-prod": {
    //                     "update": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/anexsys-cli",
    //                     "install": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/anexsys-cli/windows.tar.gz",
    //                     "version": "0.10.0"
    //                 },
    //                 "darwin-x64-prod": {
    //                     "update": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/anexsys-cli",
    //                     "install": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/anexsys-cli/macos.tar.gz",
    //                     "version": "0.10.0"
    //                 },
    //                 "linux-x64-prod": {
    //                     "update": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/anexsys-cli",
    //                     "install": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/anexsys-cli/linux.tar.gz",
    //                     "version": "0.10.0"
    //                 }
    //             }
    //         },
    //         "illuminate": {
    //             data: {
    //                 "win32-x64-prod": {
    //                 "update": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/illuminate",
    //                 "install": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/illuminate/windows.zip",
    //                 "version": "0.0.1"
    //                 },
    //                 "darwin-x64-prod": {
    //                     "update": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/illuminate",
    //                     "install": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/illuminate/macos.dmg",
    //                     "version": "0.0.1"
    //                 },
    //                 "linux-x64-prod": {
    //                     "update": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/illuminate",
    //                     "install": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/illuminate/linux.tar.gz",
    //                     "version": "0.0.01"
    //                 }
    //             }
    //         },
    //         "ebible": {
    //             data: {
    //                 "win32-x64-prod": {
    //                 "update": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/ebible",
    //                 "install": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/ebible/eBible.zip",
    //                 "version": "0.0.1"
    //                 },
    //                 "darwin-x64-prod": {
    //                     "update": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/ebible",
    //                     "install": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/ebible/eBible.dmg",
    //                     "version": "0.0.1"
    //                 },
    //                 "linux-x64-prod": {
    //                     "update": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/ebible",
    //                     "install": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/ebible/eBible.tar.gz",
    //                     "version": "0.0.01"
    //                 }
    //             }
    //         },
    //         "processing-client": {
    //             data: {
    //                 "win32-x64-prod": {
    //                 "update": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/ProcessingClient",
    //                 "install": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/ProcessingClient/ProcessingClient.zip",
    //                 "version": "0.0.1"
    //                 },
    //                 "darwin-x64-prod": {
    //                     "update": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/ProcessingClient",
    //                     "install": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/ProcessingClient/ProcessingClient.dmg",
    //                     "version": "0.0.1"
    //                 },
    //                 "linux-x64-prod": {
    //                     "update": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/ProcessingClient",
    //                     "install": "https://anexsys-app-builds.s3.eu-west-2.amazonaws.com/ProcessingClient/ProcessingClient.tar.gz",
    //                     "version": "0.0.01"
    //                 }
    //             }
    //         },
    //     }
    //     let res;
    //     try {
    //         res = await axios({
    //             method: 'get',
    //             url: `${API_ENDPOINT}updates-json?app=${appCode}`,
    //             headers: { 'X-Amz-Security-Token': store.getState().token }
    //         });
    //     } catch (e) {
    //         if (process.env.NODE_ENV === 'development') {
    //             console.log(' ~ FEEDING WITH FOLLOWING DEMO DATA: ~ \n', JSON.stringify(devResponse[appCode], null, 2));
    //             res = devResponse[appCode];
    //         } else {
    //             console.error(e);
    //             return reject(e);
    //         }
    //     }
    //     const oldVersions = res && res.data && res.data.oldVersions;
    //     let currentVersion = res && res.data;
    //     if (currentVersion && currentVersion.oldVersions) {
    //         delete currentVersion.oldVersions;
    //     }

    //     const processed = currentVersion && Object.keys(currentVersion).reduce((acc, key) => [
    //         ...acc,
    //         {
    //             platform: friendlyPlatform(key),
    //             link: res.data[key].install,
    //             version: res.data[key].version,
    //             oldVersions
    //         }
    //     ], []);
    //     resolve(processed);
    // }),
}

export default {
    auth,
    s3proxy,
};
