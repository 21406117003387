import { createStore } from 'redux';
// import { getToken, setAuthenticationStatus, toggelNotificationStatus } from '../store/actions';
import { GET_TOKEN, SET_AUTHENTICATION_STATUS, TOGGLE_NOTIFICATIONS_VISIBILITY, UPDATE_APPS } from '../store/actionTypes';

// token capture
const isTokenInQS = new URL(window.location.href).searchParams.get('token');
if (isTokenInQS) { localStorage.setItem('token', isTokenInQS) }

// declare initial state
const initialState = {
    token: localStorage.getItem('token') || null,
    isAuth: 'PENDING',
    isNotificationClosed: true,
    notificationCount: 1,
    apps: {}
}

// reducer cases
const reducer = (state = initialState, action) => {
    // console.log('reducer:', action);
    switch (action.type) {
        case SET_AUTHENTICATION_STATUS:
            // return Object.assign({}, state, { isAuth: action.payload});
            return {
                ...state,
                isAuth: action.isAuth
            };

        case TOGGLE_NOTIFICATIONS_VISIBILITY:
            return {
                ...state,
                isNotificationClosed: !state.isNotificationClosed,
                notificationCount: 0
            }
        case GET_TOKEN:
            // return Object.assign({}, state, { token: state.token });
            return {
                ...state,
                token: state.token
            }
        case UPDATE_APPS: 
            return {
                ...state,
                apps: action.apps
            }
        default:
            return state;
    }
}

// exportable store as per environment
const store = process.env.NODE_ENV === 'production'
    ? createStore(reducer)
    : createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;
