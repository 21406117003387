import React from 'react';
import { ListApps } from './';

function Home(props) {
    return <div style={{ width: 'calc(100% - 50px)', height: '90%', padding: '25px' }}>
        <ListApps />
    </div>
};

export default Home;
