import React from 'react';
import { BrowserRouter, Route, Link, Switch } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SubdirectoryArrowLeft from '@material-ui/icons/SubdirectoryArrowLeft'
import Hidden from '@material-ui/core/Hidden';
import { AppDetails } from './';
import { connect } from 'react-redux';

import { } from '../store/actions';

class ListApps extends React.Component {

	render() {
		const displayApps = () => {
			let buttons = [];
			let routes = [];

			Object.keys(this.props.apps).forEach((app, index) => {
				const a = this.props.apps[app]
				buttons = [
					...buttons,
					<ListItem button divider component={Link} to={a.app.toLowerCase()} key={index} >
						<ListItemText primary={a.app} />
					</ListItem>
				];
				routes = [
					...routes,
					<Route
						exact
						key={index}
						path={`/${a.app.toLowerCase()}`}
						render={
							() => <AppDetails app={a} />
					} />
				];
			});
			return { buttons, routes };
		}
		const display = displayApps();
		return (
			<BrowserRouter>
				<Grid container spacing={4}>
					<Grid item xs={12} sm={12} md={2}>
						<List
							component="nav"
							style={{
								marginTop: '15px',
								width: '100%'
							}
						}>
							{display.buttons}
						</List>
					</Grid>
					<Grid item xs={12} sm={12} md={10}>
						<Switch>
							<Route
								exact
								path="/"
								render={() => {
									return <Hidden only={['sm', 'xs']}>
										<Paper
											style={{
												marginTop: '30px',
												textAlign: 'center',
												padding: '11px 0 12px 0',
												color: '#444'
											}}>Please select an application<br /><br /><SubdirectoryArrowLeft />
										</Paper>
									</Hidden>
								}} />
							{display.routes}
						</Switch>
					</Grid>
				</Grid>
			</BrowserRouter>
		)
	}
}

const mapStateToProps = (state) => state;
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ListApps);
