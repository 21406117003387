import React, { Component } from 'react';
import { connect } from 'react-redux';
import utils from './utils/utils'
import './App.scss';
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

import { TopBar, Home } from './components';

import { setAuthenticationStatus, updateApps } from './store/actions';

let interval
class App extends Component {
	async componentDidMount() {
		const tokenStatus = await utils.auth.verify(this.props.token);
		this.props.setAuthenticationStatus(tokenStatus);
		this.bootstrapApps();
		interval = setInterval(this.bootstrapApps, 60000)
	};

	componentWillUnmount() {
		clearInterval(interval)
	}

	bootstrapApps = async () => {
		const indexJson = await utils.s3proxy.readIndexJson();
		const currentAppNames = Object.keys(this.props.apps)
		const appNames = [ ...new Set([ ...currentAppNames, ...((indexJson && indexJson.apps) || []) ]) ]
		const appJson = await Promise.all(appNames.map(a => utils.s3proxy.readJson(a)))
		const apps = appJson && appJson.length && appJson.reduce((acc, app) => {
			if (app.app && acc[app.app] && acc[app.app].updated >= app.updated) {
				return acc
			}
			return { ...acc, [app.app]: app }
		}, this.props.apps || {})
		this.props.updateApps(apps)
	}

	render() {
		const renderPerAuthStatus = this.props.isAuth === 'AUTHORIZED'
		? <Switch>
				<Route exact path="/" component={Home} />
				<Route exact path="/home" render={() => (<Redirect to="/" />)} />
				<Route component={Home} />
			</Switch>
		: <Route render={
				() => {
					if (this.props.isAuth === 'REJECTED') {
						setTimeout(() => window.location.replace(`${process.env.REACT_APP_AUTH_ANEXSYS_NET}/ui-app-dashboard`), 300)
					}
					return (<h3 style={{ textAlign: 'center', color: 'lightgrey' }}>...</h3>);
				}
			} />;

		const renderLogout = <Route path="/logout" render={() => {
			document.body.classList.add('animated', 'hinge');
			localStorage.removeItem('token');
			setTimeout(() => window.location.replace(`${process.env.REACT_APP_AUTH_ANEXSYS_NET}/auth/signout`), 400);
			return (<div />);
		}} />;

		return <BrowserRouter>
			<div>
				<TopBar app={this.props} />
				{renderLogout}
				{renderPerAuthStatus}
			</div>
		</BrowserRouter>;
	}
}

const mapStateToProps = state => state;
const mapDispatchToProps = { setAuthenticationStatus, updateApps };

export default connect(mapStateToProps, mapDispatchToProps)(App);
