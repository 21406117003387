import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import HomeIcon from '@material-ui/icons/Home';
import MailIcon from '@material-ui/icons/Mail';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import Tooltip from '@material-ui/core/Tooltip';
// import Notifications from './Notifications';

import { toggleNotificationVisibility } from '../store/actions'

const TopBar = props => {

	return (
		<div style={{ width: '100%' }}>
			<AppBar position="static">
				<Toolbar className="UiQuery">

					<IconButton component={Link} to="/home" color="inherit">
						<Tooltip title="Home" aria-label="Home">
							<HomeIcon />
						</Tooltip>
					</IconButton>
					<Typography variant="h6" color="inherit" noWrap>APP Dashboard</Typography>

					<div style={{ flexGrow: 1 }} />

					<div>
						<IconButton color="inherit" style={{ display: props.isAuth === 'XXX_AUTHORIZED' ? null : 'none' }}>
							<Badge badgeContent={4} color="secondary"><MailIcon /></Badge>
						</IconButton>

						{/* <IconButton color="inherit" style={{ display: props.isAuth==='AUTHORIZED' ? null : 'none' }} onClick={ props.toggleNotificationVisibility }>
							<Tooltip title="Show/Hide Notifications" aria-label="Show/Hide Notifications">
								<Badge badgeContent={props.notificationCount} color="secondary"><NotificationsIcon/></Badge>
							</Tooltip>
						</IconButton>
						<Notifications hidden={props.isNotificationClosed} /> */}

						<IconButton component={Link} to="/logout">
							<Tooltip title="Logout" aria-label="Logout">
								<ExitToAppIcon style={{ color: '#ffffff' }} />
							</Tooltip>
						</IconButton>
					</div>

				</Toolbar>
			</AppBar>
		</div>
	)
};

const mapStateToProps = (state) => state;
const mapDispatchToProps = { toggleNotificationVisibility };

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
