import { GET_TOKEN, SET_AUTHENTICATION_STATUS, TOGGLE_NOTIFICATIONS_VISIBILITY, UPDATE_APPS } from './actionTypes'

export const getToken = (token) => {
    return {
        type: GET_TOKEN,
        token: token
    }
};

export const setAuthenticationStatus = (isAuth) => {
    return {
        type: SET_AUTHENTICATION_STATUS,
        isAuth: isAuth
    }
    // isAuth: payload
};

export const toggleNotificationVisibility = (isNotificationClosed) => {
    return {
        type: TOGGLE_NOTIFICATIONS_VISIBILITY,
        isNotificationClosed: !isNotificationClosed,
        notificationCount: 0
    }
};

export const updateApps = (apps) => {
    return { type: UPDATE_APPS, apps }
}