import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import Icon from '@material-ui/core/Icon'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import utils from '../utils/utils';
import LinkDialog from './LinkDialog';
import { connect } from 'react-redux';

const platforms = ['win32-x86', 'win32-x64', 'darwin-x64', 'linux-x64'];
const platformNames = {
	'win32-x86': 'Windows (32 Bit)',
	'win32-x64': 'Windows (64 Bit)',
	'darwin-x64': 'Mac OSX',
	'linux-x64': 'Linux'
};

const initialLinkGen = { open: false, props: {} };

const AppDetails = ({ app, ...props }) => {
	console.log('appDetails', app)
	const appPlatforms = platforms.filter(p => app.hasOwnProperty(p)) || [];
	const [platformOpen, setPlatformOpen] = useState(appPlatforms.reduce((acc, cur) => ({ ...acc, [cur]: false }), {}));
	const [showDev, setShowDev] = useState(false);
	const [linkGen, setLinkGen] = useState(initialLinkGen);

	const versionTitle = (versionNumber, version) => `v${versionNumber} - ${new Date(version.created).toLocaleDateString()}`
	const listItem = (platform, version, title) => (
		<ListItem>
			<ListItemText>{title}</ListItemText>
			<Icon onClick={() => utils.s3proxy.downloadFile(app.app, platform, version)} >get_app</Icon>
			{/* <Icon onClick={() => utils.s3proxy.generateShareLink(app.app, platform, version)} >share</Icon> */}
			<Icon onClick={() => openLinkGen(app.app, version, platform)} >share</Icon>
		</ListItem>
	)

	const openLinkGen = (app, version, platform) => {
		setLinkGen({ open: true, props: { app, version, platform }});
	}
	const closeLinkGen = () => {
		setLinkGen(initialLinkGen);
	}

	const renderDownloadButtons = (appPlatforms) => (
		<Grid container direction='row' justify='space-evenly'>
			{appPlatforms.map(p => {
				const platform = app[p];
				return (
					<Grid item>
						<Typography align='center'>{platformNames[p]}</Typography>
						<List>
							{platform.stable && listItem(p, platform.stable, `Stable Release - ${new Date(platform.versions[platform.stable].created || Date.now()).toLocaleDateString()}`)}
							{platform.latest && listItem(p, platform.latest, `Latest Release - ${new Date(platform.versions[platform.latest].created || Date.now()).toLocaleDateString()}`)}
							<Divider />
							<ListItem onClick={() => setPlatformOpen({ ...platformOpen, [p]: !platformOpen[p] })}>
								<ListItemText>See older versions</ListItemText>
								<Icon>{platformOpen[p] ? 'expand_less' : 'expand_more'}</Icon>
							</ListItem>
						</List>
						<Collapse in={platformOpen[p]}>
							<List>
								{(Object.keys(platform.versions) || [])
									.filter(v => showDev || platform.versions[v].releaseTag)
									.map(v => listItem(p, v, versionTitle(v, platform.versions[v])))
								}
							</List>
						</Collapse>
					</Grid>
				)
			})}
		</Grid>
	)
	return <Paper style={{ marginTop: '30px', padding: '1px 0 24px 0' }} className="animated fadeIn">
		<div style={{ marginLeft: '20px' }}>
			<div style={{ display: 'flex', justifyContent: 'space-around' }}>
				<h2>{app.name || app.app}</h2>
				<FormControlLabel
					control={
						<Switch
							checked={showDev}
							onChange={() => setShowDev(!showDev)}
							name='devreleases'
							color="primary"
						/>
					}
					label="Show Dev Releases?"
				/>
			</div>
			<p>{app.description || 'No description available'}</p>
			<div style={{ display: 'flex', flexWrap: 'wrap' }}>
				{app && appPlatforms && renderDownloadButtons(appPlatforms)}
			</div>
		</div>
		<LinkDialog handleClose={closeLinkGen} open={linkGen.open} {...linkGen.props}/>
	</Paper>
}

const mapStateToProps = (state) => state;
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AppDetails);
